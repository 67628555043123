import { useEffect, useRef, useState } from 'react';
import { IRoomParams } from 'interfaces';

import { useStreamsDynamicGrid } from './useStreamsDynamicGrid';

export const useStreamControl = (rooms: IRoomParams[]) => {
  const [visibleStreams, setVisibleStreams] = useState<IRoomParams[]>([]);

  const [unloadedStreams, setUnloadedStreams] = useState<IRoomParams[]>([]);

  const gridSize = useStreamsDynamicGrid(
    visibleStreams.length + unloadedStreams.length
  );

  const visibleStreamsCounter = useRef(0);

  const unloadedStreamsCounter = useRef(0);

  const maxVisibleStreams =
    unloadedStreamsCounter.current < unloadedStreams.length
      ? unloadedStreamsCounter.current
      : unloadedStreams.length;

  const getVisibleStreamsCount = (gridSize: number) => {
    switch (gridSize) {
      case 1:
        return 2;
      case 2:
        return 4;
      case 3:
        return 9;
      case 4:
        return 16;
      case 5:
        return 20;
      case 6:
        return 24;
      default:
        return 2;
    }
  };

  const totalVisibleStreams = getVisibleStreamsCount(gridSize);

  const handleVisibleStreamsChange = () => {
    visibleStreamsCounter.current =
      visibleStreamsCounter.current + maxVisibleStreams;

    const totalVisibleStreams = rooms.slice(0, visibleStreamsCounter.current);
    const totalUnloadedStreams = rooms.slice(visibleStreamsCounter.current);

    setVisibleStreams(totalVisibleStreams);

    setUnloadedStreams(totalUnloadedStreams);
  };

  useEffect(() => {
    if (Number.isFinite(totalVisibleStreams)) {
      visibleStreamsCounter.current = totalVisibleStreams;
      unloadedStreamsCounter.current = totalVisibleStreams;
    }
  }, [totalVisibleStreams]);

  useEffect(() => {
    const totalVisibleRooms = rooms.slice(0, visibleStreamsCounter.current);
    const totalUnloadedRooms = rooms.slice(visibleStreamsCounter.current);

    setVisibleStreams(totalVisibleRooms);
    setUnloadedStreams(totalUnloadedRooms);
  }, [visibleStreamsCounter, rooms]);

  return {
    visibleStreams,
    unloadedStreams,
    maxVisibleStreams,
    handleVisibleStreamsChange,
  };
};
