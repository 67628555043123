import { useEffect, useState } from 'react';
import { PADDING } from 'constants/common';

import { useBreakPoint } from './useBreakPoint';
import { useWindowSize } from './useWindowSize';

const GAP = 5;
const MIN_VERTICAL_RATIO = 9;
const MAX_HORIZONTAL_RATIO = 16;

export const useStreamHeight = (
  roomsLength: number,
  screenHeight: number,
  panelWidth: number
) => {
  const [streamHeight, setStreamHeight] = useState(0);
  const breakPoint = useBreakPoint(true);
  const { height } = useWindowSize();
  const paddingGap = roomsLength > 2 ? PADDING - GAP : PADDING;

  useEffect(() => {
    const calculateCount = (rooms: number, breakpoint: string) => {
      if (breakpoint === 'mobile' || breakpoint === 'tablet') return 1;

      if (rooms <= 2) return 1;

      if (rooms >= 3 && rooms <= 6) return 2;

      if (rooms >= 7 && rooms <= 12) return 3;

      if (rooms >= 13 && rooms <= 20) return 4;

      return 5;
    };

    const count = calculateCount(roomsLength, breakPoint);

    if (count === 1) {
      setStreamHeight(screenHeight - paddingGap);

      return;
    }

    if (height) {
      const baseHeight = screenHeight / count;
      const adjustedHeight = baseHeight - paddingGap;

      const minHeight =
        (panelWidth * MIN_VERTICAL_RATIO) / MAX_HORIZONTAL_RATIO - paddingGap;

      setStreamHeight(Math.max(adjustedHeight, minHeight));
    }
  }, [height, roomsLength, breakPoint, panelWidth, screenHeight]);

  return streamHeight;
};
