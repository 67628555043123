import { useBreakPoint } from './useBreakPoint';

export const useStreamsDynamicGrid = (rooms: number) => {
  const breakPoint = useBreakPoint(true);

  switch (true) {
    case rooms === 1:
      return 1;

    case rooms <= 4: {
      if (breakPoint === 'mobile' || breakPoint === 'large-screen') return 1;

      return 2;
    }

    case rooms <= 9: {
      if (breakPoint === 'mobile') return 1;

      if (breakPoint === 'tablet') return 2;

      return 3;
    }

    case rooms <= 16: {
      if (breakPoint === 'mobile') return 1;

      if (breakPoint === 'tablet') return 2;

      if (breakPoint === 'tablet-landscape') return 3;

      return 4;
    }

    default: {
      switch (breakPoint) {
        case 'mobile':
          return 1;
        case 'tablet':
          return 2;
        case 'tablet-landscape':
          return 3;
        case 'desktop':
          return 4;
        case 'monitor':
          return 5;
        case 'large-screen':
          return 6;
        default:
          return 4;
      }
    }
  }
};
